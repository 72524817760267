import type { SearchPlaceResolvers } from '../../__codegen__/__graphql__/resolvers'
import { PlaceSearchSuccessResponseDocumentsInnerComponentPlaceFetchOperationStatusEnum } from '../../__codegen__/__openapi__/search-front-server'

export const SearchPlace: SearchPlaceResolvers = {
  id(parent) {
    return `SearchPlace#${parent.meta.docId}`
  },
  _id(parent) {
    return parent.source.id
  },
  createdAt(parent) {
    if (!parent.source?.createdAt?.iso) {
      return
    }

    return parent.source.createdAt.iso
  },
  description(parent) {
    return parent.source.description ?? ''
  },
  descriptionWithoutEmoji(parent) {
    return parent.source.descriptionWithoutEmoji ?? ''
  },
  regionName(parent) {
    return parent.fetch.regionName ?? ''
  },
  categoryId(parent) {
    return parent.source.categoryId ?? ''
  },
  categoryText(parent) {
    return parent.source.categoryText ?? ''
  },
  displayCategoryName(parent) {
    return parent.source.displayCategoryName ?? ''
  },
  name(parent) {
    return parent.source.name
  },
  location(parent) {
    return parent.source.location ?? ''
  },
  address(parent) {
    return parent.source.address ?? ''
  },
  jibunAddress(parent) {
    return parent.source.jibunAddress ?? ''
  },
  reviewCount(parent) {
    return parent.source.totalReviewCount ?? 0
  },
  followsCount(parent) {
    return parent.source.followsCount ?? 0
  },
  storeOnSale(parent) {
    return parent.source.storeOnSale ?? false
  },
  reservable(parent) {
    return parent.source.reservable ?? false
  },
  hasCoupon(parent) {
    return parent.fetch.hasCoupon ?? false
  },
  distanceMeter(parent) {
    if (!parent.source.distanceMeter) {
      return null
    }

    return parent.source.distanceMeter
  },
  themeIds(parent) {
    return parent.source.themeIds ?? []
  },
  operationStatus(parent) {
    if (!parent.fetch.operationStatus) {
      return null
    }

    return operationStatusEnumMapper(parent.fetch.operationStatus)
  },
  bestReview(parent) {
    if (!parent.source.bestReview) {
      return null
    }

    return {
      userName: parent.source.bestReview?.userName,
      content: parent.source.bestReview?.content,
    }
  },
  picture(parent) {
    return parent.fetch.picture
      ? {
          url: parent.fetch.picture.url,
          thumbnail: parent.fetch.picture.thumbnail,
          medium: parent.fetch.picture.medium,
        }
      : null
  },
  bgImage(parent) {
    return parent.fetch.bgImage
      ? {
          url: parent.fetch.bgImage.url,
          thumbnail: parent.fetch.bgImage.thumbnail,
          medium: parent.fetch.bgImage.medium,
        }
      : null
  },
  reviewStarRatingAverage(parent) {
    return parent.source.reviewStarRatingAverage ?? 0
  },
  images(parent) {
    return (
      parent.fetch.images?.map((image) => ({
        url: image.url,
        thumbnail: image.thumbnail,
        medium: image.medium,
      })) ?? []
    )
  },
}

const operationStatusEnumMapper = (
  operationStatus: PlaceSearchSuccessResponseDocumentsInnerComponentPlaceFetchOperationStatusEnum
) => {
  switch (operationStatus) {
    case PlaceSearchSuccessResponseDocumentsInnerComponentPlaceFetchOperationStatusEnum.UNSPECIFIED:
      return 'OPERATION_STATUS_UNSPECIFIED'
    case PlaceSearchSuccessResponseDocumentsInnerComponentPlaceFetchOperationStatusEnum.OPEN:
      return 'OPERATION_STATUS_OPEN'
    case PlaceSearchSuccessResponseDocumentsInnerComponentPlaceFetchOperationStatusEnum.CLOSED_FOR_PREPARATION:
      return 'OPERATION_STATUS_CLOSED_FOR_PREPARATION'
    case PlaceSearchSuccessResponseDocumentsInnerComponentPlaceFetchOperationStatusEnum.BREAK_TIME:
      return 'OPERATION_STATUS_BREAK_TIME'
    case PlaceSearchSuccessResponseDocumentsInnerComponentPlaceFetchOperationStatusEnum.CLOSED_TODAY:
      return 'OPERATION_STATUS_CLOSED_TODAY'
    case PlaceSearchSuccessResponseDocumentsInnerComponentPlaceFetchOperationStatusEnum.OPEN_24_HOURS:
      return 'OPERATION_STATUS_OPEN_24_HOURS'
    default:
      return 'UNKNOWN'
  }
}
