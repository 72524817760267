import type { Resolvers } from '../../__codegen__/__graphql__/resolvers'

export const KeywordUserNotificationMutations: Resolvers['Mutation'] = {
  /**
   * 키워드 알림 등록하기
   */
  async createUserKeywordNotification(parent, args, ctx) {
    const result =
      await ctx.services.keywordNotification.createUserKeywordNotification({
        keyword: args.input?.keyword,
      })

    if (!result) {
      throw new Error('Failed to create keyword notification')
    }

    return result
  },
}

export const CreateUserKeywordNotificationOutput: Resolvers['CreateUserKeywordNotificationOutput'] =
  {
    __resolveType(parent) {
      if ('userKeyword' in parent) {
        return 'CreateUserKeywordNotificationOutputResult'
      } else {
        return 'CreateUserKeywordNotificationOutputError'
      }
    },
  }
