import { IS_ENV_SSR, STAGE } from '../constants/common'
import type { NodeOnlyRenderPlatformType } from '../context/NodeOnlyRenderPlatformProvider'
import RenderEnvironmentError from '../../error/class/RenderEnvironmentError'

const RENDER_PLATFORM = {
  ANDROID: 'Android',
  CUPERTINO: 'Cupertino',
  WEB: 'Web',
} as const

export type RenderPlatformType =
  (typeof RENDER_PLATFORM)[keyof typeof RENDER_PLATFORM]

export const getRenderPlatformFromUserAgent = (): RenderPlatformType => {
  if (STAGE === 'development') {
    return RENDER_PLATFORM.WEB
  }

  const userAgentLowerCase = window.navigator.userAgent.toLowerCase()
  if (/android/i.test(userAgentLowerCase)) {
    return RENDER_PLATFORM.ANDROID
  }

  if (/iphone|ipad|ipod/i.test(userAgentLowerCase)) {
    return RENDER_PLATFORM.CUPERTINO
  }

  return RENDER_PLATFORM.WEB
}

export const getRenderEnvironment = ({
  __NODE_ONLY_renderPlatform,
}: {
  __NODE_ONLY_renderPlatform: NodeOnlyRenderPlatformType
}) => {
  if (!IS_ENV_SSR) {
    const renderPlatform = getRenderPlatformFromUserAgent()

    return {
      isIOS: 'Cupertino' === renderPlatform,
      isAndroid: 'Android' === renderPlatform,
      isWeb: 'Web' === renderPlatform,
      platform: renderPlatform,
    }
  }

  if (!__NODE_ONLY_renderPlatform) {
    throw new RenderEnvironmentError(
      `__NODE_ONLY_renderPlatform is not defined. IS_ENV_SSR: ${IS_ENV_SSR}`
    )
  }

  return {
    isIOS: 'Cupertino' === __NODE_ONLY_renderPlatform,
    isAndroid: 'Android' === __NODE_ONLY_renderPlatform,
    isWeb: 'Web' === __NODE_ONLY_renderPlatform,
    platform: __NODE_ONLY_renderPlatform,
  }
}

export type RenderEnvironmentType = ReturnType<typeof getRenderEnvironment>
