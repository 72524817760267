import axios from 'axios'
import axiosRetry from 'axios-retry'

import {
  IS_ENV_SSR,
  NETWORK_TIMEOUT,
  RETRY_COUNT,
  SEARCH_FRONT_SERVER_ENDPOINT,
} from '../../_app/constants/common'
import { addXSearchHeaders } from '../../_app/utils/addCustomHeader'
import type { SearchReferrerType } from '../../referrer/types'
import { captureException } from '../../_app/utils/captureException'
import to from 'await-to-js'

import type { AppType, UserType } from '../../_app/context/FoundationProvider'

import { SEARCH_EXPERIMENT_RESPONSE_KEY } from '../../experiment/constants/experiment'
import { getExperimentPayloadFromHeader } from '../../experiment/utils/getExperimentPayloadFromHeader'

import daangnAxiosInterceptors from '../../plantae/daangnAxiosInterceptors'
import {
  plantaeAuthPlugin,
  plantaeRequestIdPlugin,
  plantaeUserAgentPlugin,
  plantaeCommonHeadersPlugin,
  plantaeSearchOriginPlugin,
  plantaeSearchWebVersionPlugin,
  plantaeInjectServerSideHeaderPlugin,
} from '../../plantae/plugins'
import plantaeKarrotSessionIdPlugin from '../../plantae/plugins/plantaeKarrotSessionIdPlugin'
import { PreprocessApi } from '../../__codegen__/__openapi__/search-front-server'
import type { NodeOnlySSRBypassHeadersType } from '../../_app/context/NodeOnlySSRBypassHeaderProvider'

export function getServiceAggregatedPreProcess({
  app,
  user,
  __NODE_ONLY_bypassHeaders,
}: {
  app: AppType
  user: UserType
  __NODE_ONLY_bypassHeaders?: NodeOnlySSRBypassHeadersType
}) {
  return ServiceAggregatedPreProcess({
    baseUrl: SEARCH_FRONT_SERVER_ENDPOINT,
    app,
    user,
    __NODE_ONLY_bypassHeaders,
  })
}

type AggregatedPreProcessParamsType = {
  query: string
  regionId: number
  referrer: SearchReferrerType
}

export type ServiceAggregatedPreProcessType = ReturnType<
  typeof ServiceAggregatedPreProcess
>

const ServiceAggregatedPreProcess = ({
  baseUrl,
  app,
  user,
  __NODE_ONLY_bypassHeaders,
}: {
  baseUrl: string
  app: AppType
  user: UserType
  __NODE_ONLY_bypassHeaders?: NodeOnlySSRBypassHeadersType
}) => {
  const headers: Record<string, string> = {
    'Content-Type': 'application/json',
  }

  const axiosInstance = axios.create({
    baseURL: baseUrl,
    headers: headers,
    timeout: NETWORK_TIMEOUT,
  })
  daangnAxiosInterceptors({
    client: axiosInstance,
    plugins: IS_ENV_SSR
      ? [plantaeInjectServerSideHeaderPlugin({ __NODE_ONLY_bypassHeaders })]
      : [
          plantaeAuthPlugin({ fallbackAuthToken: user.authToken }),
          plantaeRequestIdPlugin(),
          plantaeKarrotSessionIdPlugin({ app }),
          plantaeUserAgentPlugin({ userAgent: app.userAgent }),
          plantaeCommonHeadersPlugin(),
          plantaeSearchOriginPlugin(),
          plantaeSearchWebVersionPlugin(),
        ],
  })

  axiosRetry(axiosInstance, {
    retries: RETRY_COUNT,
    retryDelay: () => 0,
    retryCondition: () => true,
    shouldResetTimeout: true,
  })
  const client = new PreprocessApi(undefined, baseUrl, axiosInstance)

  return {
    /**
     *  검색 전처리 API(탭순서, 추천탭, 오타교정)
     */
    aggregatedPreProcess: async ({
      query,
      regionId,
      referrer,
    }: AggregatedPreProcessParamsType) => {
      const [error, resp] = await to(
        client.apiV1PreprocessSearchGet(
          {
            query: query,
            regionId: String(regionId),
          },
          { headers: addXSearchHeaders({ ...referrer }) }
        )
      )

      if (error) {
        captureException(error)
        return null
      }

      if (!resp?.data) {
        return null
      }

      return {
        ...resp.data,
        [SEARCH_EXPERIMENT_RESPONSE_KEY]: getExperimentPayloadFromHeader(
          resp.headers
        ),
      }
    },
  }
}
