import authPlugin from '@daangn/plantae-plugin-auth'
import {
  getCurrentEnvironment,
  karrotBridge,
} from '../../bridge/utils/bridgeMaker'

function getOptions({
  isApp,
  fallbackAuthToken,
}: {
  isApp: boolean
  fallbackAuthToken?: string
}) {
  if (isApp) {
    return {
      options: {
        fallbackAuthToken: isApp,
      },
    }
  }

  if (fallbackAuthToken) {
    return {
      options: {
        fallbackAuthToken: {
          value: fallbackAuthToken, // local 환경 테스트
        },
      },
    }
  }

  return {
    options: {
      fallbackAuthToken: false,
    },
  }
}

const plantaeAuthPlugin = ({
  fallbackAuthToken,
}: {
  fallbackAuthToken?: string
}) => {
  const isApp =
    getCurrentEnvironment().isAndroid || getCurrentEnvironment().isIOS
  return authPlugin({
    bridge: karrotBridge,
    ...getOptions({ isApp, fallbackAuthToken }),
  })
}

export default plantaeAuthPlugin
