import { useCallback, useMemo } from 'react'
import snakecaseKeys from 'snakecase-keys'
import { compactMap } from '../../_app/utils/helpers'

import { debounce } from 'lodash-es'
import { STAGE } from '../../_app/constants/common'
import {
  getCurrentEnvironment,
  karrotBridge,
} from '../../bridge/utils/bridgeMaker'

const paramsToString = (obj: { [key: string]: any }) => {
  return JSON.stringify(obj)
}

export function useLogger() {
  const daangnSDKLogEvent = useCallback(
    ({
      name,
      params = {},
    }: {
      name: string
      params?: {
        [key: string]: any
      }
    }) => {
      const snakeCaseParams = snakecaseKeys({
        ...compactMap(params),
      })
      if (getCurrentEnvironment().isWeb) {
        console.log({
          target: 'KARROT',
          name: `client_${name}`,
          params: snakeCaseParams,
        })
      } else {
        if (STAGE === 'alpha' || STAGE === 'development') {
          console.info(`client_${name}`)
          console.log(snakeCaseParams)
        }
        karrotBridge.logEvent({
          analytics: {
            target: 'KARROT',
            name: `client_${name}`,
            params: paramsToString(snakeCaseParams),
          },
        })
      }
      return {
        target: 'KARROT',
        name: `client_${name}`,
        params: snakeCaseParams,
      }
    },
    []
  )

  return useMemo(
    () => ({
      karrotLog: debounce(daangnSDKLogEvent, 500),
      daangnSDKLogEvent,
    }),
    [daangnSDKLogEvent]
  )
}

export type karrotLoggerType = ({
  name,
  params,
}: {
  name: string
  params?: {
    [key: string]: any
  }
}) => void
