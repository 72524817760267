import to from 'await-to-js'
import axios from 'axios'
import axiosRetry from 'axios-retry'
import {
  IS_ENV_SSR,
  NETWORK_TIMEOUT,
  RETRY_COUNT,
  SEARCH_FRONT_SERVER_ENDPOINT,
} from '../../_app/constants/common'
import { captureException } from '../../_app/utils/captureException'
import daangnAxiosInterceptors from '../../plantae/daangnAxiosInterceptors'
import {
  plantaeAuthPlugin,
  plantaeRequestIdPlugin,
  plantaeCommonHeadersPlugin,
  plantaeUserAgentPlugin,
  plantaeSearchWebVersionPlugin,
  plantaeInjectServerSideHeaderPlugin,
} from '../../plantae/plugins'
import type { AppType, UserType } from '../../_app/context/FoundationProvider'

import { PropertyApi } from '../../__codegen__/__openapi__/search-front-server/api/property-api'
import type { ApiV1PropertyFleaMarketPriceFreeTextsGet200ResponseAnyOfProperties } from '../../__codegen__/__openapi__/search-front-server'
import type { NodeOnlySSRBypassHeadersType } from '../../_app/context/NodeOnlySSRBypassHeaderProvider'

export type ServiceXPropertyType = ReturnType<typeof ServiceXProperty>

export function getServiceXProperty({
  app,
  user,
  __NODE_ONLY_bypassHeaders,
}: {
  app: AppType
  user: UserType
  __NODE_ONLY_bypassHeaders?: NodeOnlySSRBypassHeadersType
}) {
  return ServiceXProperty({
    baseUrl: SEARCH_FRONT_SERVER_ENDPOINT,
    app,
    user,
    __NODE_ONLY_bypassHeaders,
  })
}

const ServiceXProperty = ({
  baseUrl,
  app,
  user,
  __NODE_ONLY_bypassHeaders,
}: {
  baseUrl: string
  app: AppType
  user: UserType
  __NODE_ONLY_bypassHeaders?: NodeOnlySSRBypassHeadersType
}) => {
  const headers: Record<string, string> = {
    'Content-Type': 'application/json',
  }
  const axiosInstance = axios.create({
    headers,
    timeout: NETWORK_TIMEOUT,
  })
  daangnAxiosInterceptors({
    client: axiosInstance,
    plugins: IS_ENV_SSR
      ? [plantaeInjectServerSideHeaderPlugin({ __NODE_ONLY_bypassHeaders })]
      : [
          plantaeAuthPlugin({ fallbackAuthToken: user.authToken }),
          plantaeRequestIdPlugin(),
          plantaeUserAgentPlugin({ userAgent: app.userAgent }),
          plantaeCommonHeadersPlugin(),
          plantaeSearchWebVersionPlugin(),
        ],
  })

  axiosRetry(axiosInstance, {
    retries: RETRY_COUNT,
    retryDelay: () => 0,
    retryCondition: () => true,
    shouldResetTimeout: true,
  })

  const client = new PropertyApi(undefined, baseUrl, axiosInstance)

  let sharingTextByCategory: ApiV1PropertyFleaMarketPriceFreeTextsGet200ResponseAnyOfProperties | null =
    null
  let sharingTextByCategoryPromise: ReturnType<
    typeof client.apiV1PropertyFleaMarketPriceFreeTextsGet
  > | null = null

  return {
    getSharingTextByCategory: async () => {
      if (sharingTextByCategory) {
        return sharingTextByCategory
      }

      if (!sharingTextByCategoryPromise) {
        sharingTextByCategoryPromise =
          client.apiV1PropertyFleaMarketPriceFreeTextsGet()
      }

      const [error, resp] = await to(sharingTextByCategoryPromise)

      if (error) {
        captureException(error)
        return null
      }

      if (!resp?.data.properties) {
        return null
      }

      sharingTextByCategory = resp.data.properties
      return sharingTextByCategory
    },
  }
}
