import { useEffect } from 'react'
import { appBuildType } from '../../_app/utils/userAgent'
import { getPortalPlugin } from './PortalPlugin'
import {
  SEARCH_FRONT_SERVER_ENDPOINT,
  VERSION,
} from '../../_app/constants/common'

const SEARCH_WEBVIEW_VCONSOLE_ELEMENT_ID = 'search-webview-vconsole'
const SEARCH_WEBVIEW_VCONSOLE_ASSET_URL = `${SEARCH_FRONT_SERVER_ENDPOINT}/static/js/vendor.vconsole.3-15-2.min.js`

export const useVConsole = () => {
  useEffect(() => {
    if (
      typeof window !== 'undefined' &&
      appBuildType(window.navigator.userAgent).isDebugApp
    ) {
      ;(async () => {
        if (document.getElementById(SEARCH_WEBVIEW_VCONSOLE_ELEMENT_ID)) {
          return
        }

        const script = document.createElement('script')
        script.src = SEARCH_WEBVIEW_VCONSOLE_ASSET_URL
        script.id = SEARCH_WEBVIEW_VCONSOLE_ELEMENT_ID
        script.async = true
        script.onload = () => {
          const vConsole = new window.VConsole({
            onReady: function (vConsole: any) {
              const vConsoleSwitch = document.querySelector(
                '.vc-switch'
              ) as HTMLElement

              vConsoleSwitch.textContent = VERSION
            },
          })
          const portalPlugin = getPortalPlugin()

          if (portalPlugin) {
            vConsole.addPlugin(portalPlugin)
          }
        }
        document.body.appendChild(script)
      })()
    }
  }, [])
}
