import 'src/packages/navbar/SearchInputNavbar.css.ts.vanilla.css?source=#H4sIAAAAAAAAA7VXTW+jMBC991cgrSo1ByMSwralpzZSpV5WK+1pT5VjJmDVsZExTbOr/e9rG0ggQAJpeglg8Hszz/MV9xdgSZIXnubqB35fYvm6EFxhykG+vvJVvJlvPOfvleNENEsZ3obOisHHg17Y0EgloTP1vGvzuMTkLZYi5xEiggkZOu9Y3iCUAUT6Z425oqR4hVKcgkQRrHDO1OTh6t+V27bjJ47hSYO27Jl224MZjTmiCtZZ6BDgCqRZTnEUUR6Hjud4biBh3UNn75/ER8Uy62Y57ibBDEofY4m3SIszOTBi6t4aK6w5t4U9GlXISCsicURzbb3n+rtXSmKeUUUFD1vkju9562yoQyEmir6D9WucG7PSjaO2nLBkgTkBVhnjd6ubigoeLzPBcgX1SHOns0qVBGicqObaXmR3NmvItxJyHRa3DCv4fYMC79p6RHKZGe9TQauAQRtYvlGFFE5RolmYYapUshgpljq8DgURKSZUbU+fyYES9WMpMawLffjeEHAtqzJevz7ySAoaVWxzS6NEGjpBkbeyFPJEZhziLnKdwopyUSEHe+R5MAa5AvhuAQ6qykonP8roH6hwHIfpWoD25+/XkujDfGpDoEwovWRLQ5pqbuNB6HDBYZ9yWulmVu8/ELkyVPuFAYlyXybKmBgi+m737ts8uHt+fh6kVxjq0yCQCKYdseINsDCwFg5Cr5zI7Je6XBMhsYnFZu4W8pyDSGxMoWWu1OVAJWS6qWRfhHq2CH0JeWtxaqUr+O7b9uAG5aV8HobeSsu7Q/y5f9fAr56P4T9KwBXgvQU0BVtnX7NmS9Bm6FJ2EqpPDNwSoylFcfFnp9p4k6SlyfKQpughNYb5vLg5xmRmkxcieGs+Id297ZOtpmau3zc3lCV9cKEa6FffcUVFyT93QBlBdLEeeYSzFSbQcm9Iix/FcP5IFgwayfqMXDCRwZOtkK3wXY0arz8Z1d0BfHZIHfXrYkFUY6nA46IqmmllhdeUaWTKE5BU1WeN3ezRMW50zSi16afrz5TapkIHQ5psEcNLYDPdpuKcYYl2Gyc7mE05MZ0BVGydtIavcVC1rQUUKB0wKDNqm7o2Eq2x2wJeuuJ+RXGth05ftUs6u+C00aGONaZOjlbtoT1NcNrTAv8DXGYErCoQAAA=';
import { createRuntimeFn as _7a468 } from '@vanilla-extract/recipes/createRuntimeFn';
export var BackIconContainer = _7a468({defaultClassName:'SearchInputNavbar_BackIconContainer__nfgw4wc',variantClassNames:{platform:{Android:'SearchInputNavbar_BackIconContainer_platform_Android__nfgw4wd',Cupertino:'SearchInputNavbar_BackIconContainer_platform_Cupertino__nfgw4we'}},defaultVariants:{},compoundVariants:[]});
export var CloseButton = _7a468({defaultClassName:'SearchInputNavbar_CloseButton__nfgw4wg',variantClassNames:{platform:{Android:'SearchInputNavbar_CloseButton_platform_Android__nfgw4wh',Cupertino:'SearchInputNavbar_CloseButton_platform_Cupertino__nfgw4wi'}},defaultVariants:{},compoundVariants:[]});
export var CloseButtonContainer = 'SearchInputNavbar_CloseButtonContainer__nfgw4wf';
export var Container = 'SearchInputNavbar_Container__nfgw4w0';
export var Input = _7a468({defaultClassName:'SearchInputNavbar_Input__nfgw4w6',variantClassNames:{platform:{Android:'SearchInputNavbar_Input_platform_Android__nfgw4w7',Cupertino:'SearchInputNavbar_Input_platform_Cupertino__nfgw4w8'}},defaultVariants:{},compoundVariants:[]});
export var InputArea = _7a468({defaultClassName:'SearchInputNavbar_InputArea__nfgw4w9',variantClassNames:{platform:{Android:'SearchInputNavbar_InputArea_platform_Android__nfgw4wa',Cupertino:'SearchInputNavbar_InputArea_platform_Cupertino__nfgw4wb'}},defaultVariants:{},compoundVariants:[]});
export var InputBox = 'SearchInputNavbar_InputBox__nfgw4w2';
export var InputCancel = _7a468({defaultClassName:'SearchInputNavbar_InputCancel__nfgw4w3',variantClassNames:{platform:{Android:'SearchInputNavbar_InputCancel_platform_Android__nfgw4w4',Cupertino:'SearchInputNavbar_InputCancel_platform_Cupertino__nfgw4w5'}},defaultVariants:{},compoundVariants:[]});
export var PageBackContainer = 'SearchInputNavbar_PageBackContainer__nfgw4w1';