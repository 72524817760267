import type { ReactNode } from 'react'
import { createContext, useContext } from 'react'

export type NodeOnlySSRBypassHeaderType = {
  [key: string]: string | undefined
} | null

export type NodeOnlySSRBypassHeadersType =
  | NodeOnlySSRBypassHeaderType[]
  | undefined
  | null

const NodeOnlySSRBypassHeaderContext =
  createContext<NodeOnlySSRBypassHeadersType>(null)

export const NodeOnlySSRBypassHeaderProvider = (props: {
  headers: NodeOnlySSRBypassHeadersType
  children: ReactNode
}) => {
  return (
    <NodeOnlySSRBypassHeaderContext.Provider value={props.headers}>
      {props.children}
    </NodeOnlySSRBypassHeaderContext.Provider>
  )
}

export const useNodeOnlySSRBypassHeaderContext = () => {
  const state = useContext(NodeOnlySSRBypassHeaderContext)
  return state
}
