import type {
  AdMediationResolvers,
  ConversionResolvers,
  TrackingUrlResolvers,
  UserActionResolvers,
} from '../../__codegen__/__graphql__/resolvers'
import {
  AdConversionSchemaTypeEnum,
  AdMediationSchemaDspEnum,
  AdTrackingUrlSchemaHostTypeEnum,
  AdUserActionSchemaTypeEnum,
} from '../../__codegen__/__openapi__/search-front-server'

export const AdMediation: AdMediationResolvers = {
  id(parent) {
    return `AdMediation#${parent.hash}`
  },
  dsp(parent) {
    if (Object.values(AdMediationSchemaDspEnum).includes(parent.dsp)) {
      return parent.dsp
    }
    return AdMediationSchemaDspEnum.UNSPECIFIED
  },
}

export const AdMediationTrackingUrl: TrackingUrlResolvers = {
  host_type(parent) {
    if (
      Object.values(AdTrackingUrlSchemaHostTypeEnum).includes(parent.host_type)
    ) {
      return parent.host_type
    }
    return AdTrackingUrlSchemaHostTypeEnum.UNSPECIFIED
  },
}

export const AdMediationUserAction: UserActionResolvers = {
  type(parent) {
    if (Object.values(AdUserActionSchemaTypeEnum).includes(parent.type)) {
      return parent.type
    }
    return AdUserActionSchemaTypeEnum.UNSPECIFIED
  },
}

export const AdMediationConversion: ConversionResolvers = {
  type(parent) {
    if (Object.values(AdConversionSchemaTypeEnum).includes(parent.type)) {
      return parent.type
    }
    return AdConversionSchemaTypeEnum.UNSPECIFIED
  },
}
