/* tslint:disable */
/* eslint-disable */
/**
 * search front swagger
 * Fastify swagger API
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { AdDisplayAdDetailSchema } from './ad-display-ad-detail-schema';
import { AdProductAdDetailSchema } from './ad-product-ad-detail-schema';
import { AdSearchAdDetailSchema } from './ad-search-ad-detail-schema';
import { AdTrackingUrlSchema } from './ad-tracking-url-schema';
import { AdUserActionSchema } from './ad-user-action-schema';
import { AdVideoAdExperimentDetailSchema } from './ad-video-ad-experiment-detail-schema';

/**
 * 
 * @export
 * @interface AdMediationSchema
 */
export interface AdMediationSchema {
    /**
     * 
     * @type {string}
     * @memberof AdMediationSchema
     */
    'dsp': AdMediationSchemaDspEnum;
    /**
     * 
     * @type {string}
     * @memberof AdMediationSchema
     */
    'id_in_dsp': string;
    /**
     * hash = dsp:id_in_dsp
     * @type {string}
     * @memberof AdMediationSchema
     */
    'hash': string;
    /**
     * 외부 광고일 경우 overflow 최상단에 locallized_ad_source_name의 광고임을 표기해야 합니다.
     * @type {string}
     * @memberof AdMediationSchema
     */
    'localized_ad_source_name': string;
    /**
     * 
     * @type {string}
     * @memberof AdMediationSchema
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof AdMediationSchema
     */
    'thumbnail_url': string;
    /**
     * 
     * @type {string}
     * @memberof AdMediationSchema
     */
    'target_url': string;
    /**
     * 
     * @type {Array<AdTrackingUrlSchema>}
     * @memberof AdMediationSchema
     */
    'impression_tracking_urls': Array<AdTrackingUrlSchema>;
    /**
     * 
     * @type {Array<AdTrackingUrlSchema>}
     * @memberof AdMediationSchema
     */
    'click_tracking_urls': Array<AdTrackingUrlSchema>;
    /**
     * 
     * @type {Array<AdUserActionSchema>}
     * @memberof AdMediationSchema
     */
    'user_actions': Array<AdUserActionSchema>;
    /**
     * 
     * @type {AdDisplayAdDetailSchema}
     * @memberof AdMediationSchema
     */
    'display_ad_detail'?: AdDisplayAdDetailSchema;
    /**
     * 
     * @type {AdProductAdDetailSchema}
     * @memberof AdMediationSchema
     */
    'product_ad_detail'?: AdProductAdDetailSchema;
    /**
     * 
     * @type {AdSearchAdDetailSchema}
     * @memberof AdMediationSchema
     */
    'search_ad_detail'?: AdSearchAdDetailSchema;
    /**
     * 
     * @type {AdVideoAdExperimentDetailSchema}
     * @memberof AdMediationSchema
     */
    'video_ad_experiment_detail'?: AdVideoAdExperimentDetailSchema;
}

export const AdMediationSchemaDspEnum = {
    UNSPECIFIED: 'DSP_UNSPECIFIED',
    KARROT: 'DSP_KARROT',
    NAVER: 'DSP_NAVER',
    COUPANG: 'DSP_COUPANG',
    ELEVENST: 'DSP_ELEVENST',
    EDNPLUS: 'DSP_EDNPLUS'
} as const;

export type AdMediationSchemaDspEnum = typeof AdMediationSchemaDspEnum[keyof typeof AdMediationSchemaDspEnum];


