import axios from 'axios'
import axiosRetry from 'axios-retry'
import to from 'await-to-js'

import daangnAxiosInterceptors from '../../plantae/daangnAxiosInterceptors'
import {
  plantaeAuthPlugin,
  plantaeCommonHeadersPlugin,
  plantaeInjectServerSideHeaderPlugin,
  plantaeKarrotSessionIdPlugin,
  plantaeRequestIdPlugin,
  plantaeSearchOriginPlugin,
  plantaeSearchWebVersionPlugin,
  plantaeUserAgentPlugin,
} from '../../plantae/plugins'
import {
  IS_ENV_SSR,
  NETWORK_TIMEOUT,
  RETRY_COUNT,
  SEARCH_FRONT_SERVER_ENDPOINT,
} from '../../_app/constants/common'
import type { AppType, UserType } from '../../_app/context/FoundationProvider'

import { captureException } from '../../_app/utils/captureException'
import type { SearchExperimentReferrerType } from '../../referrer/types'
import type { FunnelFromIdTypes } from '../../referrer/constants'
import { SEARCH_EXPERIMENT_RESPONSE_KEY } from '../../experiment/constants/experiment'
import { getExperimentPayloadFromHeader } from '../../experiment/utils/getExperimentPayloadFromHeader'
import type { AutocompleteApiApiV1AutocompleteGetRequest } from '../../__codegen__/__openapi__/search-front-server'
import {
  AutocompleteApi,
  Configuration,
} from '../../__codegen__/__openapi__/search-front-server'
import {
  addExperimentXSearchHeader,
  addFunnelFromXSearchHeader,
} from '../../_app/utils/addCustomHeader'
import type { NodeOnlySSRBypassHeadersType } from '../../_app/context/NodeOnlySSRBypassHeaderProvider'

interface FeedAutoCompleteV4SearchRequestParamsType
  extends AutocompleteApiApiV1AutocompleteGetRequest {
  referrer: {
    funnelFromId: FunnelFromIdTypes
    experiment: SearchExperimentReferrerType
  }
}
export type ServiceAutoCompleteType = ReturnType<typeof ServiceAutoComplete>

export const getServiceAutoComplete = ({
  user,
  app,
  __NODE_ONLY_bypassHeaders,
}: {
  user: UserType
  app: AppType
  __NODE_ONLY_bypassHeaders?: NodeOnlySSRBypassHeadersType
}) => {
  return ServiceAutoComplete({
    baseUrl: SEARCH_FRONT_SERVER_ENDPOINT,
    user,
    app,
    __NODE_ONLY_bypassHeaders,
  })
}

const ServiceAutoComplete = ({
  baseUrl,
  user,
  app,
  __NODE_ONLY_bypassHeaders,
}: {
  baseUrl: string
  user: UserType
  app: AppType
  __NODE_ONLY_bypassHeaders?: NodeOnlySSRBypassHeadersType
}) => {
  const headers: Record<string, string> = {
    'Content-Type': 'application/json',
  }
  const axiosInstance = axios.create({
    headers,
    timeout: NETWORK_TIMEOUT,
  })
  daangnAxiosInterceptors({
    client: axiosInstance,
    plugins: IS_ENV_SSR
      ? [plantaeInjectServerSideHeaderPlugin({ __NODE_ONLY_bypassHeaders })]
      : [
          plantaeAuthPlugin({ fallbackAuthToken: user.authToken }),
          plantaeRequestIdPlugin(),
          plantaeKarrotSessionIdPlugin({ app }),
          plantaeUserAgentPlugin({ userAgent: app.userAgent }),
          plantaeCommonHeadersPlugin(),
          plantaeSearchOriginPlugin(),
          plantaeSearchWebVersionPlugin(),
        ],
  })

  axiosRetry(axiosInstance, {
    retries: RETRY_COUNT,
    retryDelay: () => 0,
    retryCondition: () => true,
    shouldResetTimeout: true,
  })

  const client = new AutocompleteApi(
    new Configuration({
      apiKey: user.authToken,
    }),
    baseUrl,
    axiosInstance
  )

  return {
    /**
     * 자동완성 목록 조회
     */
    getFeedAutoComplete: async ({
      regionId,
      prefix,
      pageSize,
      referrer,
    }: FeedAutoCompleteV4SearchRequestParamsType) => {
      const [error, resp] = await to(
        client.apiV1AutocompleteGet(
          { regionId, pageSize, prefix },
          {
            headers: {
              ...addFunnelFromXSearchHeader({
                funnelFromId: referrer.funnelFromId,
              }),
              ...addExperimentXSearchHeader(referrer.experiment),
            },
          }
        )
      )
      if (error) {
        captureException(error)
        return null
      }
      if (!resp?.data) {
        return null
      }

      return {
        ...resp.data,
        [SEARCH_EXPERIMENT_RESPONSE_KEY]: getExperimentPayloadFromHeader(
          resp.headers
        ),
      }
    },
  }
}
