import type { ReactNode } from 'react'
import { createContext, useContext, useMemo } from 'react'
import {
  getRenderEnvironment,
  type RenderPlatformType,
} from '../utils/getRenderEnvironment'

export type NodeOnlyRenderPlatformType = RenderPlatformType | undefined | null

const NodeOnlyRenderPlatformContext =
  createContext<NodeOnlyRenderPlatformType>(null)

export const NodeOnlyRenderPlatformProvider = (props: {
  renderPlatform: NodeOnlyRenderPlatformType
  children: ReactNode
}) => {
  return (
    <NodeOnlyRenderPlatformContext.Provider value={props.renderPlatform}>
      {props.children}
    </NodeOnlyRenderPlatformContext.Provider>
  )
}

export const useNodeOnlyRenderPlatformContext = () => {
  const state = useContext(NodeOnlyRenderPlatformContext)
  return state
}

export const useRenderEnvironment = () => {
  const __NODE_ONLY_renderPlatform = useNodeOnlyRenderPlatformContext()
  const renderEnvironment = getRenderEnvironment({
    __NODE_ONLY_renderPlatform,
  })
  return useMemo(() => renderEnvironment, [renderEnvironment])
}
